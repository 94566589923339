import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../../components/common/drawer'
import { useSnackbarManager } from '../../../../../../components/common/snackbar'
import { getErrorMessage } from '../../../../../../utilities/parsers'
import { fetchVendorEmployee } from '../../../../api'
import { memberSchema, MemberSchema } from '../../create/schema'
import { addMemberToTeam } from '../api'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  id: string
  id2: string
  handleCallback?: () => void
}

export default function CreateEmployee({
  isDrawerOpen,
  handleClose,
  subSection,
  handleCallback,
  handleRefresh,
  id,
  id2,
}: Props) {
  const { enqueueSnackbar } = useSnackbarManager()

  const [teamPage, setTeamPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const getTeamsList = async (search?: string, page?: number) => {
    const data = await fetchVendorEmployee(id, {
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    console.log(data, 'dataaaa')
    const next = data?.data?.next ? teamPage + 1 : 0
    setTeamPage(next)
    return data?.data?.results
  }

  const formBuilderProps = [
    {
      name: 'employee',
      label: 'Employee',
      required: true,
      getData: getTeamsList,
      async: true,
      id: 'employee_id',
      paginationEnabled: true,
      nextBlock: teamPage,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Employee',
    },
  ]

  const methods = useForm<MemberSchema>({
    resolver: zodResolver(memberSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: any, saveAndContinue: boolean) => {
    setIsLoading(true)
    const payload = new FormData()
    payload.append('member_id', data?.employee_id)
    addMemberToTeam(id, id2, payload)
      .then(() => {
        enqueueSnackbar('Member Added successfully', {
          variant: 'success',
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        saveAndContinue ? handleClearAndClose() : handleClear()
        handleRefresh?.()
        handleCallback?.()
        setIsLoading(false)
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  const handleClear = () => {
    methods.reset({
      employee_id: '',
    })
  }
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        actionLoader={isLoading}
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        handleSaveAndContinue={
          subSection ? undefined : handleSubmit((data) => onSubmit(data, false))
        }
        saveAndContinueLabel={'Save and Add Another'}
        handleSubmit={handleSubmit((data) => onSubmit(data, true))}
        title="Add Team Member"
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
