import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useCreateVendorRoles } from '../../../api'
import { RoleSchema, roleSchema } from './schema'
type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: (data: any) => void
}

export default function CreateRole({
  isDrawerOpen,
  handleClose,
  subSection,
  handleCallback,
  handleRefresh,
  parentParams,
}: Props) {
  const [saveContinue, setSaveContinue] = useState(false)

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
  })

  const formBuilderProps = [
    textField('name', 'Role Name', 'Enter role name', true, true),
  ]

  const methods = useForm<RoleSchema>({
    resolver: zodResolver(roleSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = (data: any) => {
    methods.reset({
      name: '',
    })
    if (!saveContinue) {
      handleCallback?.(data)
      handleClearAndClose()
    } else {
      handleCallback?.(data)
    }
  }
  const { mutate, isLoading } = useCreateVendorRoles(handleSubmission)
  const onSubmit = (data: any, saveAndContinue: boolean) => {
    setSaveContinue(saveAndContinue)
    mutate(
      {
        input: data,
        id: parentParams?.id,
      },
      {
        onSuccess: () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          saveAndContinue ? handleRefresh?.() : ''
        },
      }
    )
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        actionLoader={isLoading}
        handleSaveAndContinue={
          subSection ? undefined : handleSubmit((data) => onSubmit(data, true))
        }
        handleSubmit={handleSubmit((data) => onSubmit(data, false))}
        title="Create New Role"
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
