import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { TextField } from '../../../../components/common'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import CustomDatePicker from '../../../../components/common/inputs/CustomDatePicker'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import config from '../../../../config'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { downloadFromServer } from '../../../../utilities/commonUtilities'
import { debounce } from '../../../../utilities/debounce'
import { statusClassGen } from '../../../../utilities/generators'
import { getErrorMessage } from '../../../../utilities/parsers'
import {
  changeInvoiceDate,
  deleteInvoice,
  generateInvoice,
  getOrderInvoiceList,
} from '../../api'
import { checkDetailPermission } from '../../permission'
import ShareInvoice from '../../shareInvoice'
import { getColumns } from './invoiceColumns'

const OrderInvoice = () => {
  const [activeRow, setActiveRow] = useState<any>(null)
  const [invoice, setInvoice] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cancelInvoice, setCancelInvoice] = useState(false)
  const [reason, setReason] = useState('')
  const [isFetching, setFetching] = useState(false)
  const [data, setData] = useState<any>()
  const [shareInvoice, setShareInvoice] = useState(false)
  const [orderId, setOrderId] = useState('')

  const [openChangeDatePopUp, setOpenChangeDatePopUp] = useState(false)
  const [isGenerateInvoiceClick, setIsGenerateInvoiceClick] = useState(false)
  const [updatedDate, setUpdatedDate] = useState<any>()
  const [error, setError] = useState<any>()

  const { enqueueSnackbar } = useSnackbarManager()

  const params = useParams()

  const { setActiveTab, detailsData, handleCallBack } = useOutletContext<{
    setActiveTab: (id: string) => void
    detailsData?: any
    handleCallBack?: () => void
  }>()
  const [detailData, setDetailData] = useState<any>(detailsData)

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_INVOICES')
      getData()
      setDetailData(detailsData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailsData]
  )
  const handleCallback = () => {
    getData()
    handleCallBack?.()
  }
  const getData = () => {
    setFetching(true)
    getOrderInvoiceList(params?.id).then((res) => {
      if (res?.data?.results) {
        setData(res?.data?.results)
      }
      setFetching(false)
    })
  }

  const handleCancelInvoice = () => {
    deleteInvoice(activeRow?.id ?? '', reason)
      .then(() => {
        enqueueSnackbar('Invoice Cancelled successfully', {
          variant: 'success',
        })
        setCancelInvoice(false)
        handleCallback()
      })
      .catch((error: any) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
        setInvoice(false)
      })
  }

  const generateInvoiceData = debounce(() => {
    setIsLoading(true)
    if (params?.id) {
      generateInvoice(params?.id ?? '', {
        remarks: '',
        footer: '',
        invoice_date: moment(updatedDate).format('YYYY-MM-DD'),
      })
        .then(() => {
          enqueueSnackbar('Invoice generated successfully', {
            variant: 'success',
          })
          setInvoice(false)
          handleCallback()
          setTimeout(() => {
            setIsLoading(false)
          }, 1000)
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error?.response.data.error), {
            variant: 'error',
          })
          setInvoice(false)
          setIsLoading(false)
        })
    }
  }, 500)

  const onViewAction = (r: any, from: string) => {
    if (from == 'invoiceId' && r?.status !== 'Cancelled') {
      return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${params.id}/invoices/${r.id}`
    } else if (from === 'view') {
      handleViewRecieptOpen(r)
    } else {
      return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${params.id}/invoices/${r.id}`
    }
  }

  const handleDeleteClose = () => {
    setCancelInvoice(false)
    setActiveRow(null)
  }
  const onShareAction = (row: any) => {
    setOrderId(row?.id)
    setShareInvoice(true)
  }
  const onDownloadAction = (r: any) => {
    if (r?.document) {
      downloadFromServer(r?.document, r.display_file_name)
    }
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setCancelInvoice(true)
  }
  const handleViewRecieptOpen = (res: any) => {
    if (res.id) {
      window.open(
        `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${params.id}/invoices/${res.id}`,
        '_blank'
      )
    }
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )
  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  const handleGenerateInvoice = () => {
    setIsGenerateInvoiceClick(false)
    handlePopUpClose()
    if (
      [
        'completed',
        'order_completed_payment_pending',
        'order_completed_payment_done',
      ].includes(detailData?.order_status?.code) ||
      detailData?.remaining_amount == '0.00'
    ) {
      generateInvoiceData()
    } else {
      setInvoice(true)
    }
  }
  const handleGenerateInvoicePopUp = () => {
    setIsGenerateInvoiceClick(true)
    setUpdatedDate(moment(new Date()).format('YYYY-MM-DD'))
    setOpenChangeDatePopUp(true)
  }
  const handleDateUpdateOnSubmit = () => {
    if (isGenerateInvoiceClick) {
      handleGenerateInvoice()
    } else {
      handleChangeInvoiceDate()
    }
  }
  const handleChangePopUp = (data: any) => {
    setActiveRow(data)
    setUpdatedDate(data?.invoice_date)
    setOpenChangeDatePopUp(true)
  }
  const handlePopUpClose = () => {
    setIsGenerateInvoiceClick(false)
    setActiveRow('')
    setOpenChangeDatePopUp(false)
  }
  const handleChangeInvoiceDate = async () => {
    if (error) {
      return
    }
    const res = await changeInvoiceDate(
      params?.id,
      activeRow?.id,
      moment(updatedDate).format('YYYY-MM-DD')
    )
    if (res?.status == 200) {
      enqueueSnackbar(res?.data || 'Invoice date updated successfully', {
        variant: 'success',
      })
      getData()
      handlePopUpClose()
    } else {
      enqueueSnackbar('Failed to update invoice date', {
        variant: 'error',
      })
    }
  }
  const handleDateChange = (item: any) => {
    setError('')
    const date = item.value
    setUpdatedDate(date ? moment(date).format('MM/DD/YYYY') : '')
    if (!date) {
      setError({ date: { message: 'Required' } })
    } else if (date && isNaN(date.getTime())) {
      setError({ date: { message: 'Invalid date format' } })
    }
  }
  return (
    <>
      {data?.length !== 0 ? (
        <div className=" p-4">
          <QbsTable
            data={data ?? []}
            dataRowKey="id"
            toolbar={true}
            search={true}
            isLoading={isFetching}
            columns={columns}
            handleResetColumns={() => handleResetColums()}
            tableHeaderActions={
              <div className="flex gap-2">
                <Button
                  // onClick={() => handleGenerateInvoice()}
                  onClick={() => handleGenerateInvoicePopUp()}
                  label={'Generate Invoice'}
                  className="se"
                  disabled={
                    detailData?.has_active_invoice ||
                    (blockActionsbyStatus(detailData?.order_status?.code, [
                      'cancelled',
                      'converted',
                      'rejected',
                      'order_cancelled',
                    ]) &&
                      detailData?.is_locked)
                  }
                  icon="plus"
                  isLoading={isLoading}
                  hidden={!checkDetailPermission('add', 'invoice')}
                  size="xs"
                />
              </div>
            }
            actionProps={[
              {
                icon: <Icons name="eye" />,
                action: (row: any) => handleViewRecieptOpen(row),
                title: 'View Invoice',
                hidden: !checkDetailPermission('view', 'invoice'),
                toolTip: 'View Invoice',
                hide: (row: any) =>
                  blockActionsbyStatus(row?.status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                  ]),
              },
              {
                icon: <Icons name="close" />,
                action: (row: any) => handleDeleteOpen(row),
                title: 'Cancel',
                toolTip: 'Cancel',
                hidden:
                  !checkDetailPermission('delete', 'invoice') ||
                  (blockActionsbyStatus(detailData?.order_status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                  ]) &&
                    detailData?.is_locked),

                hide: (row: any) =>
                  blockActionsbyStatus(row?.status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'order_completed_payment_done',
                    'converted',
                    // 'order_completed_payment_pending',
                    'rejected',
                  ]) || row?.status == 'Cancelled'
                    ? true
                    : false ||
                      (blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'order_completed_payment_done',
                        'converted',
                        // 'order_completed_payment_pending',
                        'rejected',
                      ]) &&
                        detailData?.is_locked),
              },
              {
                icon: <Icons name="share" />,
                action: (row: any) => onShareAction(row),
                title: 'Share',
                toolTip: 'Share',
                hidden:
                  !checkDetailPermission('send', 'invoice') ||
                  (blockActionsbyStatus(detailData?.order_status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                    // 'order_completed_payment_done',
                  ]) &&
                    detailData?.is_locked),
                hide: (row: any) =>
                  (blockActionsbyStatus(row?.status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                  ]) &&
                    detailData?.is_locked) ||
                  row?.deleted === null
                    ? false
                    : true ||
                      (blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        // 'order_completed_payment_done',
                      ]) &&
                        detailData?.is_locked),
              },
              {
                icon: <Icons name="download" />,
                action: (row: any) => onDownloadAction(row),
                title: 'Download',
                toolTip: 'Download',
                hidden:
                  !checkDetailPermission('download', 'invoice') ||
                  (blockActionsbyStatus(detailData?.order_status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                    // 'order_completed_payment_done',
                  ]) &&
                    detailData?.is_locked),
                hide: (row: any) =>
                  (blockActionsbyStatus(row?.status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                  ]) &&
                    detailData?.is_locked) ||
                  row?.deleted === null
                    ? false
                    : true ||
                      (blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        // 'order_completed_payment_done',
                      ]) &&
                        detailData?.is_locked),
              },
              {
                icon: <Icons name="calendar" />,
                action: (row: any) => handleChangePopUp(row),
                title: 'Change Invoice Date',
                hidden: !checkDetailPermission('view', 'invoice'),
                toolTip: 'Change Invoice Date',
                hide: (row: any) =>
                  blockActionsbyStatus(row?.status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                  ]),
              },
            ]}
            columnToggle
          />
        </div>
      ) : (
        <div className="p-4 bg-white rounded-lg  ">
          <div className="flex flex-col items-center justify-center gap-2 py-10 min-h-[calc(100vh-390px)]">
            <img
              src={config.EMPTY_ILLUSTRATION}
              alt="bizpole"
              className="h-auto"
            />
            <p className="text-sm font-bold text-primaryText dark:text-white">
              No Invoice available
            </p>
            <p className="text-sm">
              Oops! Invoice for this order is not yet generated at the moment
            </p>
            <Button
              label={'Generate Invoice'}
              // onClick={() => handleGenerateInvoice()}
              onClick={() => handleGenerateInvoicePopUp()}
              icon="plus"
              hidden={!checkDetailPermission('add', 'invoice')}
              size="xs"
              isLoading={isLoading}
              disabled={
                detailData?.has_active_invoice ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                  // 'order_completed_payment_done',
                ]) &&
                  detailData?.is_locked)
              }
            />
            {invoice}
          </div>
        </div>
      )}

      <DialogModal
        isOpen={cancelInvoice}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleCancelInvoice()}
        actionLabel="Okay"
        body={
          <InfoBox
            content={
              <div>
                <div className="mb-5">
                  Are you sure you want to cancel this invoice? This is an
                  irreversible process and the invoice will be permanently
                  deleted.
                </div>
                <TextField
                  id="1"
                  name="reason"
                  label="Cancel Reason"
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter Cancel Reason"
                  required={false}
                />
              </div>
            }
          />
        }
      />

      <DialogModal
        isOpen={invoice}
        onClose={() => setInvoice(false)}
        title={'Are you sure?'}
        onSubmit={() => generateInvoiceData()}
        actionLoader={isLoading}
        actionLabel="Okay"
        disabled={isLoading}
        body={
          <InfoBox
            content={
              'Invoice can be generated only if full payment is done or if the order status is Completed'
            }
          />
        }
      />
      <ShareInvoice
        isDrawerOpen={shareInvoice}
        handleClose={() => setShareInvoice(false)}
        id={orderId}
      />
      <DialogModal
        isOpen={openChangeDatePopUp}
        onClose={handlePopUpClose}
        title={'Are you sure?'}
        onSubmit={() => handleDateUpdateOnSubmit()}
        secondaryAction={handlePopUpClose}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <>
            <InfoBox
              content={'Are you sure you want to change the invoice date?'}
            />
            <div className="flex flex-col gap-4">
              <div className="w-full flex flex-col gap-2">
                <CustomDatePicker
                  onChange={(date) => handleDateChange(date)}
                  name={'date'}
                  value={updatedDate ?? null}
                  placeholder={'DD-MM-YYYY'}
                  label={'Invoice Date'}
                  errors={error}
                  maxDate={new Date()}
                  required={true}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default OrderInvoice
