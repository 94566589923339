import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { sendReceipt } from '../../../../../apis/common.apis'
import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useSnackbarManager } from '../../../../common/snackbar'
import { sendReceiptSchema, SendReceiptSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: () => void
  id?: string
}

export default function Create({
  isDrawerOpen,
  handleClose,
  handleCallback,
  id,
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm<SendReceiptSchema>({
    resolver: zodResolver(sendReceiptSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })

  const { enqueueSnackbar } = useSnackbarManager()

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    textField('email', 'Email', 'Enter Email Id', true, 'text'),
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClose()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    handleCallback && handleCallback()
  }

  const onSubmit = (data: SendReceiptSchema) => {
    setIsLoading(true)
    const input = {
      email: data?.email ?? '',
    }
    sendReceipt(id ?? '', input)
      .then((res) => {
        enqueueSnackbar(res?.data, { variant: 'success' })
        handleSubmission()
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title="Share Receipt"
      actionLabel="Share"
      actionLoader={isLoading}
      disableSubmit={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
