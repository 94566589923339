export const GetFilterDetails = () => {
  return [
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'startDate',
      descSecondary: 'endDate',
      isPrimary: true,
      name: 'Order Date',
    },
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'allocationStartDate',
      descSecondary: 'allocationEndDate',
      isPrimary: true,
      name: 'Amount Allocation Date',
    },
  ]
}
