import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  createNote,
  createNoteComment,
  getNotes,
  updateNote,
  updateNoteComment,
} from '../../../apis/common.apis'
import config from '../../../config'
import { MODULES } from '../../../configs/scopes.config'
import { debounce } from '../../../utilities/debounce'
import { Button } from '../../common'
import Icons from '../../common/icons'
import { useSnackbarManager } from '../../common/snackbar'
import CommentContainer from './commentContainer'
import CreateEventLogs from './createNote'
import NotesContainer from './notesContainer'

type Props = {
  moduleUrl: string
  apisectionUrl?: string
  disabled?: boolean
  urlalter?: boolean
  checkModulePermission?: (key: string, sub: string) => boolean
}
export default function CommonNotes({
  moduleUrl,
  apisectionUrl,
  disabled = false,
  urlalter = false,
  checkModulePermission,
}: Props) {
  const textInputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [eventLogOpen, setEventLogOpen] = useState(false)
  const [notesData, setNotesData] = useState<any>([])
  const [notesItem, setNotesItem] = useState<any>()
  const [commentItem, setCommentItem] = useState<any>()
  const [editReply, setEditReply] = useState<boolean>(false)
  const [reply, setReply] = useState<string>('')

  const params = useParams()
  const { enqueueSnackbar } = useSnackbarManager()
  const handleAction = () => {
    setNotesItem({})
    setEventLogOpen(true)
  }
  const [isActive, setIsActive] = useState(false)

  const handleEdit = (value: any) => {
    setNotesItem(value)
    setEventLogOpen(true)
    setIsActive(true)
  }
  const handleGetNotes = async () => {
    const { data } = await getNotes(
      moduleUrl,
      params?.id as string,
      apisectionUrl
    )
    setNotesData(data?.results)
  }

  useEffect(() => {
    handleGetNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onNoteSubmit = (data?: any) => {
    setIsLoading(true)
    const apiFunction = data?.id ? updateNote : createNote
    apiFunction(moduleUrl, params?.id as string, data, urlalter, apisectionUrl)
      .then(() => {
        enqueueSnackbar(
          data?.id ? 'Note Updated Successfully' : 'Note Created Successfully',
          { variant: 'success' }
        )
        setEventLogOpen(false)
        handleGetNotes()
        setIsLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
        setIsLoading(false)
      })
  }
  const handleReplyComment = (data: any) => {
    setCommentItem('')
    setIsActive(true)
    setReply('')
    setTimeout(() => {
      if (textInputRef.current) {
        textInputRef.current?.focus()
      }
    }, 100)
    setNotesItem({ ...data, parentId: parent })
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const trimValue = value.trimStart()
    setReply(trimValue)
  }
  const handleCreate = () => {
    const data = {
      comment: reply,
      parent_id: commentItem?.id ?? null,
    }
    const noteId = notesItem.id ?? commentItem?.parentId
    createNoteComment(
      moduleUrl,
      params?.id as string,
      data,
      noteId,
      urlalter,
      apisectionUrl
    )
      .then(() => {
        handleGetNotes()
        setReply('')
        setIsActive(false)
        enqueueSnackbar('Comment added Successfully', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: 'error',
        })
      })
  }
  const handleUpdate = () => {
    const data = {
      comment: reply,
      parent_id: commentItem?.parent_id ?? null,
    }
    const noteId = commentItem?.lead_note_id
    updateNoteComment(
      moduleUrl,
      params?.id as string,
      data,
      noteId,
      commentItem?.id as string,
      urlalter,
      apisectionUrl
    )
      .then(() => {
        handleGetNotes()
        setEditReply(false)
        setReply('')
        setIsActive(false)
        enqueueSnackbar('Comment updated Successfully', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: 'error',
        })
      })
  }
  const handleSubmitReply = debounce(() => {
    if (editReply) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }, 500)
  const handleReply = (data: any, parent: string) => {
    setNotesItem('')
    setIsActive(true)
    setReply('')
    setEditReply(false)
    setTimeout(() => {
      if (textInputRef.current) {
        textInputRef.current?.focus()
      }
    }, 100)
    setCommentItem({ ...data, parentId: parent })
  }
  const handleCommentEdit = (data: any) => {
    setIsActive(true)
    setReply(data?.comment)
    setEditReply(true)
    setCommentItem(data)
    setTimeout(() => {
      if (textInputRef.current) {
        textInputRef.current?.focus()
      }
    }, 100)
  }
  return (
    <>
      <div
        className={
          notesData?.length > 0
            ? 'border border-formBorder rounded-lg bg-white dark:bg-black min-h-[calc(100vh-335px)] max-h-[calc(100vh-335px)] p-4 gap-3 flex flex-col w-full lg:w-2/3 xl:w-1/2'
            : 'border border-formBorder rounded-lg bg-white dark:bg-black min-h-[calc(100vh-335px)] max-h-[calc(100vh-335px)] p-4 gap-3 flex flex-col my-auto w-full lg:w-2/3 xl:w-1/2'
        }
      >
        <div className="flex items-center justify-between gap-3 flex-shrink-0">
          <p className=" text-primaryText dark:text-white font-bold text-lg">
            Notes
          </p>
          {notesData?.length > 0 && (
            <Button
              onClick={handleAction}
              size="xs"
              hidden={!checkModulePermission?.('add', MODULES.note)}
              label="Add New"
              disabled={disabled}
              icon="plus"
            />
          )}
        </div>
        <div
          className={`flex-1 overflow-y-auto p-3 gap-5 flex flex-col ${
            notesData?.length > 0 ? '' : 'items-center justify-center'
          } `}
        >
          {notesData?.length > 0 ? (
            <ul className="notesList">
              {notesData?.map((items: any) => (
                <li key={items.id}>
                  <NotesContainer
                    handleComment={handleReplyComment}
                    data={items}
                    disabled={disabled}
                    checkModulePermission={checkModulePermission}
                    handleAction={handleEdit}
                  />
                  {checkModulePermission?.('view', MODULES.notecomment) && (
                    <ul className="commentList">
                      {items?.children?.map((child: any) => (
                        <li key={child.id}>
                          <CommentContainer
                            handleClick={handleCommentEdit}
                            reply={true}
                            checkModulePermission={checkModulePermission}
                            handleReply={(data) => handleReply(data, items.id)}
                            parentName={items?.created_by?.name}
                            data={child}
                            disabled={disabled}
                          />
                          <ul className="commentList">
                            {child?.children?.map((subChild: any) => (
                              <li key={subChild.id}>
                                <CommentContainer
                                  data={subChild}
                                  checkModulePermission={checkModulePermission}
                                  handleClick={handleCommentEdit}
                                  disabled={disabled}
                                  parentName={child?.created_by?.name}
                                />
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <tr>
              <td className="bg-white dark:bg-black" colSpan={9}>
                <div className="flex flex-col items-center justify-center py-10">
                  <img
                    src={config.EMPTY_ILLUSTRATION}
                    alt="bizpole"
                    className="h-auto"
                  />
                  <p className="text-sm font-bold text-primaryText dark:text-white mt-5">
                    No notes available
                  </p>
                  <p className="text-xxs leading-4 font-medium text-secondary dark:text-white mb-5">
                    Oops! No Notes to display at the moment.
                  </p>
                  <Button
                    label="Add Notes"
                    onClick={() => handleAction()}
                    icon="plus"
                    size="xs"
                    disabled={disabled}
                    hidden={!checkModulePermission?.('add', MODULES.note)}
                    outlined={false}
                  />
                </div>
              </td>
            </tr>
          )}
        </div>
        {notesData?.length > 0 &&
          isActive &&
          !disabled &&
          (checkModulePermission?.('add', MODULES.notecomment) ||
            checkModulePermission?.('change', MODULES.notecomment) ||
            checkModulePermission?.('reply', MODULES.notecomment)) && (
            <div className="commentFormField">
              <input
                ref={textInputRef}
                onChange={(e) => handleChange(e)}
                type="text"
                value={reply ?? ''}
                placeholder="Add Comment"
                className="textfield w-full"
                onKeyDown={(e) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  e.key === 'Enter' && handleSubmitReply()
                }}
              />
              <button
                className="p-0 bg-transparent"
                onClick={handleSubmitReply}
                disabled={!reply || !isActive}
              >
                <Icons name="send" className="iconWidthSm" />
              </button>
            </div>
          )}
      </div>
      <CreateEventLogs
        isDrawerOpen={eventLogOpen}
        onSubmit={onNoteSubmit}
        values={notesItem}
        isLoading={isLoading}
        handleClose={() => setEventLogOpen(false)}
      />
    </>
  )
}
