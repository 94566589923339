import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

import InfoBox from '../../../components/app/alertBox/infoBox'
import { PrimaryFilterComponent } from '../../../components/app/filterComponents/primaryFilter'
import { Button, DialogModal } from '../../../components/common'
import ListingHeader from '../../../components/common/ListingTiles'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { useOrderAllocationReportFilterStore } from '../../../store/filterSore/orderAllocationReportStore'
import { exportOrderReport } from './api'
import { GetFilterDetails } from './filterProperties'

const OrderAllocationSummary = () => {
  const filterDetails = GetFilterDetails()
  const { pageParams, setPageParams } = useOrderAllocationReportFilterStore()
  const { advanceFilter, setAdvanceFilter } =
    useOrderAllocationReportFilterStore()

  const { filters, filterProps } = pageParams

  const searchParams = {
    ...filters,
  }
  const [exportPopup, setExportPopup] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()

  const [loader, setloader] = useState(false)

  const basicData = {
    title: 'Order Allocation Report',
    icon: 'user',
  }

  const handleFilterChange = (dta: any, name: string, id: string) => {
    const currentParams =
      useOrderAllocationReportFilterStore.getState()?.pageParams || {}

    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name] ?? undefined,
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()

    setPageParams({
      ...currentParams,
      filters,
      filterProps,
    })
  }

  useEffect(() => {
    if (
      filterProps.endDate ||
      filterProps.allocationEndDate ||
      (!filterProps.endDate && !filterProps.startDate) ||
      (!filterProps.allocationEndDate && !filterProps.allocationStartDate)
    ) {
      handleDatepicker()
    }
  }, [filterProps.endDate, filterProps.allocationEndDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
        amount_collection_from_date: filterProps?.allocationStartDate
          ? moment(filterProps?.allocationStartDate).format('DD-MM-YYYY')
          : '',
        amount_collection_to_date: filterProps?.allocationEndDate
          ? moment(filterProps.allocationEndDate).format('DD-MM-YYYY')
          : '',
      },
    })
  }, [filterProps.endDate, filterProps.allocationEndDate])

  const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value[0],
        [desc2]: event?.value[1],
      },
    })
  }

  const handleDownload = async () => {
    setloader(true)
    const response = await exportOrderReport(searchParams)
    if (response.status == 200) {
      setloader(false)
      enqueueSnackbar(response?.data, { variant: 'success' })
    } else {
      setloader(false)
      enqueueSnackbar('Failed to send data', { variant: 'error' })
    }
  }

  const handleExport = async () => {
    setExportPopup(true)
  }

  const handleExportSubmit = () => {
    setExportPopup(false)
    handleDownload()
  }

  const handleExportCancel = () => {
    setExportPopup(false)
  }

  return (
    <div className="con">
      <DialogModal
        isOpen={exportPopup}
        onClose={() => handleExportCancel()}
        title={'Are you sure?'}
        onSubmit={() => handleExportSubmit()}
        secondaryAction={() => handleExportCancel()}
        secondaryActionLabel="Cancel"
        actionLabel={'Send'}
        actionLoader={loader}
        className="z-50"
        body={
          <InfoBox content="Are you sure you want to generate the order allocation report?" />
        }
      />
      <ListingHeader data={basicData} />

      <div
        className="gap-2 mt-10 ml-5"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <PrimaryFilterComponent
          filterParams={filterDetails}
          handleFilterChange={handleFilterChange}
          filterProps={filterProps}
          advanceFilter={advanceFilter}
          handleFilterDateChange={handleFilterDateChange}
          isPrimary={true}
          setAdvanceFilter={setAdvanceFilter}
        />

        <Button
          label="Generate Report"
          icon="upload"
          outlined={true}
          onClick={handleExport}
          disabled={!filterProps.endDate || !filterProps.allocationEndDate}
          isLoading={loader}
        />
      </div>
    </div>
  )
}

export default OrderAllocationSummary
